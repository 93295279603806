import React from 'react';

import { Tooltip } from 'react-tippy';

import { DashLegend, Legend } from 'components/atoms';
import BarIndicatorWithLabels, {
	TooltipContent,
} from 'components/molecules/BarIndicatorWithLabels';

import useWindowSize from 'hooks/useWindowSize';

import tooltipStyle from 'constants/tooltipStyle';

import { getHumanNumberFormat, getPercents } from 'utils';

const TopCountriesIndicators = ({ data = {} }) => {
	const [width] = useWindowSize();
	const {
		top_countries: topCountries = [],
		total_indicators: totalIndicators = [],
		year,
	} = data;

	const total = totalIndicators.length ? totalIndicators[0].value : null;

	const borders = ['solid', 'dashed', 'dotted'];
	const legendData = [];
	const chartData = [];
	let maxValue = 0;

	// find max value from all years
	topCountries.forEach(({ data }) => {
		data.forEach(({ need_based_plan: needBasedPlan, projected }) => {
			if (maxValue < needBasedPlan) {
				maxValue = needBasedPlan;
			}
			if (maxValue < projected) {
				maxValue = projected;
			}
		});
	});

	// prepare chart data
	const sortedData = topCountries.sort((a, b) => b.year - a.year);
	const currentData = sortedData[0] || { data: [] };

	currentData.data.forEach(
		({ need_based_plan: needBasedPlan, country, projected }) => {
			chartData.push({
				value: projected,
				percents: getPercents(projected, total),
				thirdValue: needBasedPlan,
				thirdPercents: getPercents(needBasedPlan, maxValue),
				label: country,
				year: currentData.year,
				previous: [],
				length: getPercents(projected, maxValue),
				tooltip: [
					{
						label: `${currentData.year} Projected operational requirements`,
						value: projected,
					},
				],
			});
		},
	);

	// prepare prev chart data
	topCountries.forEach(({ data, year }, index) => {
		data.forEach(({ need_based_plan: needBasedPlan, country }) => {
			const countryData = chartData.find((item) => item.label === country);
			if (countryData && index !== 0) {
				countryData.previous.push({
					value: needBasedPlan,
					label: country,
					year,
					borderStyle: borders[index - 1],
					length: getPercents(needBasedPlan, maxValue),
				});
			}
			countryData?.tooltip.push({
				label: `${year} Approved country portfolio needs`,
				value: needBasedPlan,
			});
		});
		if (!legendData.find(({ label }) => label === year) && index !== 0) {
			legendData.push({
				borderStyle: borders[index - 1],
				label: `${year} Approved country portfolio needs`,
			});
		}
		legendData.reverse();
	});

	chartData.sort((a, b) => (a.value > b.value ? -1 : 1));

	return (
		<div className="indicators">
			<div className="title">
				Top 10 countries by projected operational requirements
				<div className="title__subtitle">
					In USD and % of total projected operational requirements (
					{total ? (
						<Tooltip
							{...tooltipStyle}
							theme="dark"
							position="bottom"
							className="tooltip"
							html={
								<TooltipContent
									values={totalIndicators}
									title="Total projected operational requirements"
								/>
							}
						>
							{`${getHumanNumberFormat(total)} USD`}
						</Tooltip>
					) : (
						'N/A'
					)}
					)
				</div>
			</div>
			{chartData.map((item, index) => (
				<BarIndicatorWithLabels
					tooltipData={{ values: item.tooltip }}
					withTooltip
					key={`top-countries-${item.label}-${index}`}
					truncateWidth={width < 350 ? 120 : 150}
					order={index + 1}
					{...item}
				/>
			))}
			<div className="legend-wrap">
				<Legend
					lines={['projected']}
					labelMap={{
						projected: {
							label: `${year} Projected operational requirements`,
						},
					}}
					colorScale={['#0780C0']}
				/>
				<Legend
					lines={['needBasedPlan']}
					labelMap={{
						needBasedPlan: {
							label: `${year} Approved country portfolio needs`,
						},
					}}
					colorScale={['#9deaff']}
				/>
				{legendData.map((item) => (
					<DashLegend legend={[item]} key={`${item.borderStyle}-key`} />
				))}
			</div>
		</div>
	);
};

export default TopCountriesIndicators;
