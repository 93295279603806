let pdfStyles = `
@charset "UTF-8";
.main-dashboard__panels {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;
  grid-template-columns: repeat(auto-fit, minmax(390px, 1fr)); }
  .main-dashboard__panels-left {
    grid-template-columns: 1.35fr 0.65fr; }
  .main-dashboard__panels-right {
    grid-template-columns: 0.65fr 1.35fr; }

.implementation-plan-panel {
  height: 100%; }
  .implementation-plan-panel .indicator-panel__bottom {
    flex: 1;
    display: flex;
    flex-direction: column; }

.big-panel {
  margin-top: 20px;
  margin-bottom: 60px; }
  .big-panel .panel-v2 .wfp--module__content {
    padding-top: 0;
    padding-bottom: 0; }

@media screen and (max-width: 760px) {
  .main-dashboard__panels {
    grid-template-columns: 1fr; } }

.panel-v2 .loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px; }

.panel-v2__header h1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between; }

.panel-v2__header .wfp--module__title {
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #031c2d; }

.panel-v2__header .download {
  cursor: pointer; }

.panel-v2__content {
  overflow: hidden; }
  .panel-v2__content-horizontal {
    flex-direction: row; }

.panel-v2__banner {
  position: absolute;
  width: calc(100% + 500px);
  height: 50px;
  top: 40%;
  left: -50%;
  transform: rotate(46deg);
  display: flex;
  align-items: center;
  justify-content: center; }
  .panel-v2__banner p {
    color: #fff;
    font-weight: 600;
    font-size: 18px; }
  .panel-v2__banner-error {
    background: rgba(197, 25, 45, 0.8); }

.panel-v2__footer {
  justify-content: space-between;
  height: 40px; }
  .panel-v2__footer .help-text {
    font-size: 12px;
    font-weight: bold;
    color: #8c9ba5; }
  .panel-v2__footer .info-error {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    color: #c5192d; }
    .panel-v2__footer .info-error__text {
      margin-left: 5px; }

.panel-v2 .wfp--module__inner {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column; }

.header-dashboard {
  background: #031c2d;
  flex: 1;
  padding: 20px; }

.beneficiaries-dashboard {
  min-width: 280px; }
  .beneficiaries-dashboard .header-dashboard-item__indicator .bar-indicator-row__desc {
    width: 95px; }
  .beneficiaries-dashboard .small-indicator-label .header-dashboard-item__indicator .bar-indicator-row__desc {
    width: 85px; }
  .beneficiaries-dashboard .country-breakdown-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .beneficiaries-dashboard .country-breakdown-item__link-icon {
      margin-left: 2px; }
    .beneficiaries-dashboard .country-breakdown-item .emergency-level {
      width: 20px;
      height: 20px;
      font-size: 12px; }
    .beneficiaries-dashboard .country-breakdown-item__label {
      flex: 1;
      margin: 0 5px;
      font-weight: 700;
      color: white; }
      .beneficiaries-dashboard .country-breakdown-item__label span {
        white-space: nowrap; }
    .beneficiaries-dashboard .country-breakdown-item__value {
      min-width: 60px;
      text-align: right; }
    .beneficiaries-dashboard .country-breakdown-item__label:nth-child(1) {
      margin-left: 0; }
  .beneficiaries-dashboard .country-breakdown-item:last-child {
    margin-bottom: 0; }
  .beneficiaries-dashboard .title__container .breakdown:after {
    font-family: FontAwesome;
    content: "\\f107";
    padding-left: 0.3em; }

.header-tooltip-theme.tippy-tooltip {
  background-color: #005F9B; }
  .header-tooltip-theme.tippy-tooltip .arrow-regular {
    border-top: 7px solid #005F9B;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent; }

.header-panel-dashboard, .header-dashboard {
  color: white;
  background: #031c2d;
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px; }
  .header-panel-dashboard .loading-container, .header-dashboard .loading-container {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center; }
  .header-panel-dashboard__wrap, .header-dashboard__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 1px;
    background-color: rgba(255, 255, 255, 0.22); }
  .header-panel-dashboard__rest, .header-dashboard__rest {
    display: grid;
    grid-template-columns: 2fr 1.1fr;
    grid-gap: 1px; }
  .header-panel-dashboard .header-wrap .total, .header-dashboard .header-wrap .total {
    color: #F7B825; }
    .header-panel-dashboard .header-wrap .total .value, .header-dashboard .header-wrap .total .value {
      color: #F7B825;
      font-weight: bold; }
  .header-panel-dashboard-item__list, .header-dashboard-item__list {
    display: grid;
    grid-gap: 10px; }
  .header-panel-dashboard-item__indicator__label.bar-indicator-row__item-label, .header-dashboard-item__indicator__label.bar-indicator-row__item-label {
    font-weight: 700; }
  .header-panel-dashboard-item__indicator .bar-indicator__value, .header-dashboard-item__indicator .bar-indicator__value {
    color: #fff;
    font-size: 16px; }
  .header-panel-dashboard-item__indicator .bar-indicator-row__item, .header-dashboard-item__indicator .bar-indicator-row__item {
    font-size: 16px; }
  .header-panel-dashboard-item__indicator .bar-indicator-row__desc, .header-dashboard-item__indicator .bar-indicator-row__desc {
    width: initial;
    min-width: 40px; }
  .header-panel-dashboard-item__indicator .bar-indicator .scale, .header-dashboard-item__indicator .bar-indicator .scale {
    background-color: #005F9B; }
  .header-panel-dashboard-item__indicator .bar-indicator .scale:nth-child(2), .header-dashboard-item__indicator .bar-indicator .scale:nth-child(2) {
    background-color: #73C3FE; }
  .header-panel-dashboard-item__indicator .bar-indicator .scale:nth-child(3), .header-dashboard-item__indicator .bar-indicator .scale:nth-child(3) {
    background-color: #9deaff; }
  .header-panel-dashboard-item__label, .header-dashboard-item__label {
    margin-bottom: 10px;
    color: #F7B825;
    font-weight: 700;
    font-size: 16px; }
  .header-panel-dashboard__indicators, .header-dashboard__indicators {
    background-color: #031c2d; }
    .header-panel-dashboard__indicators .title__container, .header-dashboard__indicators .title__container {
      height: 67px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid #ffffff20; }
    .header-panel-dashboard__indicators .column__title, .header-dashboard__indicators .column__title {
      font-size: 20px;
      font-weight: 600;
      color: #F7B825;
      margin-bottom: 3px; }
    .header-panel-dashboard__indicators .column__subtitle, .header-dashboard__indicators .column__subtitle {
      font-size: 16px; }
    .header-panel-dashboard__indicators .indicator-set, .header-dashboard__indicators .indicator-set {
      background-color: #031c2d; }
  .header-panel-dashboard .right-indicators, .header-dashboard .right-indicators {
    display: grid;
    grid-template-rows: minmax(100px, 120px) 1fr;
    grid-gap: 1px;
    background-color: rgba(255, 255, 255, 0.22); }
    .header-panel-dashboard .right-indicators__top, .header-dashboard .right-indicators__top {
      padding: 20px;
      background-color: #031c2d;
      min-height: 100px; }
    .header-panel-dashboard .right-indicators__bottom, .header-dashboard .right-indicators__bottom {
      padding: 20px;
      background-color: #031c2d;
      min-height: 100px; }
      .header-panel-dashboard .right-indicators__bottom .number-indicator, .header-dashboard .right-indicators__bottom .number-indicator {
        min-height: 85px; }
      .header-panel-dashboard .right-indicators__bottom .percent-indicators__label, .header-dashboard .right-indicators__bottom .percent-indicators__label {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px; }
  .header-panel-dashboard .vertical-indicators, .header-dashboard .vertical-indicators {
    padding: 20px;
    background-color: #031c2d;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .header-panel-dashboard .vertical-indicators .sub-title, .header-dashboard .vertical-indicators .sub-title {
      font-size: 12px;
      font-weight: 600; }
  .header-panel-dashboard .dark-no-data, .header-dashboard .dark-no-data {
    background-color: #031c2d; }

.header-panel-dashboard.region .net-funding-label__sub-title {
  font-weight: normal; }

.header-panel-dashboard__wrap.country {
  grid-template-columns: minmax(500px, 2fr) 1fr 1fr; }
  .header-panel-dashboard__wrap.country .header-dashboard-item__list {
    display: block; }
  .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators.loading {
    grid-template-columns: 1fr; }
  .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px; }
    .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators .header-list-indicators {
      padding-right: 10px; }
    .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators .employees-indicators {
      padding-left: 10px;
      padding-top: 7px; }

.header-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  height: 35px; }

@media only screen and (max-width: 1150px) {
  .header-panel-dashboard__wrap, .header-panel-dashboard__wrap.country {
    grid-template-columns: 1fr; }
    .header-panel-dashboard__wrap .beneficiaries-dashboard, .header-panel-dashboard__wrap.country .beneficiaries-dashboard {
      grid-gap: 20px; } }

@media only screen and (max-width: 1150px) and (min-width: 650px) {
  .header-panel-dashboard .header-wrap .header-wrap__indicators {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px; }
    .header-panel-dashboard .header-wrap .header-wrap__indicators .header-list-indicators {
      padding-right: 10px; }
    .header-panel-dashboard .header-wrap .header-wrap__indicators .employees-indicators {
      padding-top: 7px;
      padding-left: 10px; }
  .header-panel-dashboard .beneficiaries-dashboard {
    grid-gap: 20px; }
  .header-panel-dashboard .header-dashboard-item__list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none; } }

@media only screen and (max-width: 650px) {
  .header-panel-dashboard__wrap {
    margin-left: 0; }
  .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators {
    grid-template-columns: 1fr; }
    .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators .header-list-indicators {
      padding-right: 0; }
    .header-panel-dashboard__wrap.country .header-wrap .header-wrap__indicators .employees-indicators {
      padding-left: 0;
      padding-top: 20px; } }

.header-panel-dashboard.global .indicator-set {
  display: flex;
  flex-direction: column; }
  .header-panel-dashboard.global .indicator-set .indicator.vertical {
    min-height: 75px; }
  .header-panel-dashboard.global .indicator-set__grouped {
    margin-top: 40px; }
    .header-panel-dashboard.global .indicator-set__grouped .grouped-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px; }
      .header-panel-dashboard.global .indicator-set__grouped .grouped-label span {
        text-decoration: underline; }
    .header-panel-dashboard.global .indicator-set__grouped .grouped-label.arrow-down:after {
      font-family: 'FontAwesome', sans-serif;
      content: "\\f107";
      padding: 5px;
      color: white; }
    .header-panel-dashboard.global .indicator-set__grouped .grouped-indicators__item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      justify-content: space-between; }
      .header-panel-dashboard.global .indicator-set__grouped .grouped-indicators__item .label {
        font-size: 12px;
        font-weight: bold;
        justify-self: center;
        align-self: center; }
      .header-panel-dashboard.global .indicator-set__grouped .grouped-indicators__item .indicator {
        width: 60%; }
      .header-panel-dashboard.global .indicator-set__grouped .grouped-indicators__item .progress-symbol {
        color: white; }
  .header-panel-dashboard.global .indicator-set .about-indicator {
    flex-grow: 1; }

.indicator-set-switcher {
  cursor: pointer;
  width: 100%; }

.indicator-set-options {
  display: flex;
  flex-direction: column; }
  .indicator-set-options div {
    cursor: pointer;
    border-bottom: 1px solid #EFF2F5;
    color: #0b77c2;
    padding: 10px 0; }

@media only screen and (max-width: 888px) {
  .header-panel-dashboard.global .indicator-set__grouped {
    margin-top: 0; } }

.population-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1px;
  background-color: rgba(255, 255, 255, 0.22); }
  .population-items .population-item:first-child {
    padding-right: 10px; }
  .population-items .population-item:last-child {
    padding-left: 10px; }

.country .population-items {
  grid-template-columns: 1fr;
  grid-gap: 0; }
  .country .population-items .population-item {
    padding: 0; }

.population-item {
  background-color: #031c2d; }
  .population-item__block {
    margin-bottom: 20px; }
  .population-item:last-child {
    margin-bottom: 0; }
  .population-item-row {
    margin-bottom: 10px; }
    .population-item-row__label {
      margin-right: 15px;
      font-weight: 700; }
  .population-item .bar-indicator-row__desc {
    width: 65px; }

.population-item.global .population-item-row__value {
  margin-right: 15px; }

.population-item.global .population-item-row__value.breakdown {
  margin-right: 0; }

.population-item .population-item-row {
  display: flex;
  justify-content: space-between; }

.population-item .breakdown:after {
  font-family: FontAwesome;
  content: "\\f107";
  padding-left: 0.3em; }

.breakdown-label {
  white-space: nowrap;
  font-weight: 700;
  margin-bottom: 10px; }

.forcibly-displaced-tooltip.bar-tooltip .extra-data-wrap__inner:before {
  left: -5px;
  bottom: 6px; }

.breakdown-list, .forcibly-displaced-tooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
  min-width: 160px; }
  .breakdown-list__item, .breakdown-list .bar-tooltip__item, .forcibly-displaced-tooltip__item, .forcibly-displaced-tooltip .bar-tooltip__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 0; }
    .breakdown-list__item span, .breakdown-list .bar-tooltip__item span, .forcibly-displaced-tooltip__item span, .forcibly-displaced-tooltip .bar-tooltip__item span {
      font-size: 12px;
      font-weight: 400;
      margin: 0 10px 0 5px; }
    .breakdown-list__item span, .breakdown-list .bar-tooltip__item span, .forcibly-displaced-tooltip__item span, .forcibly-displaced-tooltip .bar-tooltip__item span {
      font-size: 12px;
      font-weight: 400;
      margin: 0 10px 0 5px; }
    .breakdown-list__item div:first-child, .breakdown-list .bar-tooltip__item div:first-child, .forcibly-displaced-tooltip__item div:first-child, .forcibly-displaced-tooltip .bar-tooltip__item div:first-child {
      font-weight: 600; }
  .breakdown-list__item:first-child, .breakdown-list .bar-tooltip__item:first-child, .forcibly-displaced-tooltip__item:first-child, .forcibly-displaced-tooltip .bar-tooltip__item:first-child {
    margin-top: 0; }
  .breakdown-list__item-container, .forcibly-displaced-tooltip__item-container {
    position: relative;
    margin-top: 8px; }
  .breakdown-list__item-container:first-child, .forcibly-displaced-tooltip__item-container:first-child {
    margin-top: 0; }
  .breakdown-list .total-idps, .forcibly-displaced-tooltip .total-idps {
    margin-bottom: 10px; }
  .breakdown-list__item:last-child, .forcibly-displaced-tooltip__item:last-child {
    margin-bottom: 0; }
  .breakdown-list .extra-data-wrap, .forcibly-displaced-tooltip .extra-data-wrap {
    display: flex;
    flex-direction: column;
    position: relative; }
    .breakdown-list .extra-data-wrap__item, .forcibly-displaced-tooltip .extra-data-wrap__item {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 15px;
      margin-top: 5px;
      font-size: 14px; }
      .breakdown-list .extra-data-wrap__item span, .forcibly-displaced-tooltip .extra-data-wrap__item span {
        font-size: 12px;
        font-weight: 400;
        margin: 0 10px 0 5px; }
      .breakdown-list .extra-data-wrap__item div:first-child, .forcibly-displaced-tooltip .extra-data-wrap__item div:first-child {
        margin-right: 10px; }
      .breakdown-list .extra-data-wrap__item div:before, .forcibly-displaced-tooltip .extra-data-wrap__item div:before {
        content: '';
        position: absolute;
        left: -11px;
        height: 1px;
        width: 6px;
        background: #adadad;
        bottom: 6px; }
  .breakdown-list .extra-data-wrap__inner:before, .forcibly-displaced-tooltip .extra-data-wrap__inner:before {
    content: '';
    position: absolute;
    height: 30px;
    width: 1px;
    background: #adadad;
    left: 3px;
    bottom: 6px; }

.forcibly-displaced-tooltip.bar-tooltip .extra-data-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 38px; }
  .forcibly-displaced-tooltip.bar-tooltip .extra-data-wrap__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 7px;
    font-size: 14px; }
    .forcibly-displaced-tooltip.bar-tooltip .extra-data-wrap__item div:first-child {
      margin-right: 5px; }

.forcibly-displaced-tooltip.bar-tooltip .extra-data-wrap:before {
  left: 47px;
  bottom: 14px; }
  .forcibly-displaced-tooltip.bar-tooltip .extra-data-wrap:before__item div:before {
    left: -2px;
    height: 1px;
    width: 6px;
    background: #adadad;
    bottom: 6px; }

@media only screen and (max-width: 1150px) {
  .country .population-items {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px; }
    .country .population-items .population-item:first-child {
      padding-right: 10px; }
    .country .population-items .population-item:last-child {
      padding-left: 10px; } }

@media only screen and (max-width: 650px) {
  .country .population-items {
    grid-template-columns: 1fr;
    grid-gap: 0; }
  .country .population-item:first-child {
    padding-right: 0; }
  .country .population-item:last-child {
    padding-left: 0 !important;
    padding-top: 0 !important; }
  .population-items {
    grid-template-columns: 1fr;
    grid-gap: 0; }
    .population-items .population-item:first-child {
      padding-right: 0; }
    .population-items .population-item:last-child {
      padding-left: 0;
      padding-top: 0; } }

.chart-indicator-panel, .indicator-panel {
  display: flex;
  flex-direction: column; }
  .chart-indicator-panel-empty, .indicator-panel-empty {
    height: 300px; }
  .chart-indicator-panel-horizontal, .indicator-panel-horizontal {
    flex-direction: row; }
  .chart-indicator-panel__disclaimer, .indicator-panel__disclaimer {
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    color: #5a6872; }
  .chart-indicator-panel__top, .indicator-panel__top {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    min-height: 310px; }
    .chart-indicator-panel__top .indicator, .indicator-panel__top .indicator {
      display: flex; }
    .chart-indicator-panel__top .chart, .indicator-panel__top .chart {
      display: flex;
      flex-direction: column; }
      .chart-indicator-panel__top .chart__title, .indicator-panel__top .chart__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        line-height: 26px;
        font-weight: 600;
        color: #031c2d; }
        .chart-indicator-panel__top .chart__title .subtitle, .indicator-panel__top .chart__title .subtitle {
          line-height: 23px;
          font-size: 14px;
          font-weight: 600;
          color: #5a6872; }
    .chart-indicator-panel__top .chart.center, .indicator-panel__top .chart.center {
      align-items: center; }
  .chart-indicator-panel__bottom, .indicator-panel__bottom {
    margin-top: 60px;
    flex-grow: 1; }
    .chart-indicator-panel__bottom-horizontal, .indicator-panel__bottom-horizontal {
      margin-top: 0; }
    .chart-indicator-panel__bottom .title, .indicator-panel__bottom .title {
      display: flex;
      flex-direction: column;
      line-height: 26px;
      font-size: 16px;
      font-weight: 600; }
      .chart-indicator-panel__bottom .title__subtitle, .indicator-panel__bottom .title__subtitle {
        margin-bottom: 5px;
        line-height: 23px;
        font-size: 14px;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        color: #5a6872; }
    .chart-indicator-panel__bottom .grouped-indicators, .indicator-panel__bottom .grouped-indicators {
      flex-grow: 1; }
      .chart-indicator-panel__bottom .grouped-indicators__item, .indicator-panel__bottom .grouped-indicators__item {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
        grid-column-gap: 10px; }
        .chart-indicator-panel__bottom .grouped-indicators__item .label, .indicator-panel__bottom .grouped-indicators__item .label {
          font-size: 14px;
          color: #5a6872; }
      .chart-indicator-panel__bottom .grouped-indicators__item:last-child, .indicator-panel__bottom .grouped-indicators__item:last-child {
        margin: 0; }
    .chart-indicator-panel__bottom .wfp--tabs__nav-link, .indicator-panel__bottom .wfp--tabs__nav-link {
      font-size: 16px; }
    .chart-indicator-panel__bottom .wfp--tabs__nav-item, .indicator-panel__bottom .wfp--tabs__nav-item {
      padding: 0; }

.indicator-panel {
  height: 100%; }
  .indicator-panel__bottom {
    margin-top: 0;
    height: 100%; }

.cbt-panel .chart-indicator-panel__bottom, .food-panel .chart-indicator-panel__bottom {
  margin-top: 0; }

.partners-panel-content .chart-indicator-panel {
  height: 100%;
  justify-content: space-between; }
  .partners-panel-content .chart-indicator-panel__bottom {
    margin-top: 40px; }
  .partners-panel-content .chart-indicator-panel__top {
    justify-content: initial;
    min-height: 180px; }
  .partners-panel-content .chart-indicator-panel__disclaimer {
    font-weight: 600;
    font-size: 14px;
    color: #5a6872;
    margin-bottom: 16px; }

.partners-panel-content .chart {
  margin: 0; }

.performance-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); }
  .performance-panel .performance-panel-column {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #dfe3e6;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    .performance-panel .performance-panel-column__title {
      font-size: 16px;
      font-weight: 600; }
    .performance-panel .performance-panel-column__pie-chart {
      align-self: center;
      min-height: 200px;
      margin-bottom: 60px; }
    .performance-panel .performance-panel-column__bar-chart {
      width: 100%;
      min-height: 180px;
      align-self: center;
      margin-bottom: 10px; }
      .performance-panel .performance-panel-column__bar-chart .title {
        font-size: 14px;
        font-weight: 600; }
      .performance-panel .performance-panel-column__bar-chart .subtitle {
        font-size: 12px;
        font-weight: bold;
        color: #8c9ba5; }
      .performance-panel .performance-panel-column__bar-chart .chart__wrapper {
        min-height: 180px; }
  .performance-panel .performance-panel-column:first-child {
    padding-left: 0; }
  .performance-panel .performance-panel-column:last-child {
    padding-right: 0;
    border-right: 1px solid rgba(255, 255, 255, 0); }

@media screen and (max-width: 768px) {
  .performance-panel {
    display: flex;
    flex-direction: column; }
    .performance-panel .performance-panel-column {
      padding-left: 0;
      padding-right: 0;
      border-right: none; } }

.header-general-dashboard {
  min-width: 285px;
  background-color: #031c2d; }
  .header-general-dashboard .header-wrap {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    height: 100%; }
    .header-general-dashboard .header-wrap .sub-indicators {
      margin-top: 5px; }
    .header-general-dashboard .header-wrap .one-line-indicator.total .value {
      font-weight: 700; }
    .header-general-dashboard .header-wrap .one-line-indicator {
      justify-content: space-between; }
      .header-general-dashboard .header-wrap .one-line-indicator .label {
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: normal;
        margin-right: 15px; }
      .header-general-dashboard .header-wrap .one-line-indicator .value {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.63; }
    .header-general-dashboard .header-wrap .title {
      color: #F7B825;
      font-size: 16px;
      line-height: 26px; }
    .header-general-dashboard .header-wrap__indicators {
      display: grid;
      grid-gap: 1px;
      background-color: rgba(255, 255, 255, 0.22); }
    .header-general-dashboard .header-wrap__tags {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px; }
      .header-general-dashboard .header-wrap__tags .crisis-level {
        margin-right: 10px; }
    .header-general-dashboard .header-wrap .disclaimer-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr; }
    .header-general-dashboard .header-wrap .china-disclaimer {
      display: flex;
      align-items: flex-end;
      font-size: .8em;
      background-color: #031c2d;
      padding-left: 10px;
      padding-top: 20px; }

@media only screen and (max-width: 650px) {
  .disclaimer-wrap {
    grid-template-columns: 1fr !important; }
    .disclaimer-wrap .china-disclaimer {
      padding-left: 0 !important; } }

.strategic-results-panel .horizontal-bar-chart__items {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 80px;
  grid-auto-flow: column; }

.strategic-results-panel .horizontal-bar-chart .legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .strategic-results-panel .horizontal-bar-chart .legend .mark-wrap {
    margin-right: 20px; }

@media (max-width: 876px) {
  .strategic-results-panel .horizontal-bar-chart__items {
    grid-auto-flow: row; } }

@media (max-width: 600px) {
  .strategic-results-panel .horizontal-bar-chart__items {
    grid-template-columns: repeat(auto-fit, minmax(245px, 1fr)); } }

.strategic-goal-panel .wfp--module__content {
  padding: 0; }

.strategic-goal-panel__table {
  line-height: 1.69; }
  .strategic-goal-panel__table .rt-thead .rt-th, .strategic-goal-panel__table .rt-tfoot .rt-td, .strategic-goal-panel__table .rt-tbody .rt-td {
    min-height: 95px;
    padding: 25px 5px;
    border-right: none;
    align-self: flex-start;
    line-height: normal; }
  .strategic-goal-panel__table .rt-thead .rt-th {
    padding-right: 20px; }
  .strategic-goal-panel__table .rt-tfoot .rt-td.title-column, .strategic-goal-panel__table .rt-tbody .rt-td.title-column {
    padding-left: 30px; }
  .strategic-goal-panel__table .rt-tbody .rt-tr-group:last-child {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1); }
  .strategic-goal-panel__table .rt-tfoot {
    background-color: #FBFCFC;
    font-weight: 600; }
  .strategic-goal-panel__table .rt-thead {
    background-color: #EFF2F5; }
  .strategic-goal-panel__table .title-column {
    display: flex;
    flex-direction: row;
    line-height: normal;
    align-items: center; }
    .strategic-goal-panel__table .title-column__logo {
      width: 68px;
      height: 68px;
      margin-right: 10px; }
    .strategic-goal-panel__table .title-column__label {
      font-weight: 600; }
      .strategic-goal-panel__table .title-column__label .code {
        font-size: 14px;
        line-height: 1; }
  .strategic-goal-panel__table .two-rows-cell__percent {
    color: #5a6872; }

.strategic-outcome-panel .wfp--module__content {
  padding: 0; }

.strategic-outcome-panel__table .rt-thead .rt-th, .strategic-outcome-panel__table .rt-tfoot .rt-td, .strategic-outcome-panel__table .rt-tbody .rt-td {
  min-height: 95px;
  padding: 25px 10px;
  border-right: none;
  line-height: normal; }

.strategic-outcome-panel__table .rt-thead.-header .title-header-column {
  margin-top: -95px;
  height: 235px; }

.strategic-outcome-panel__table .rt-thead.-header .rt-th {
  padding-right: 20px;
  min-height: 140px; }

.strategic-outcome-panel__table .rt-tfoot {
  border-top: solid 1px rgba(0, 0, 0, 0.1); }

.strategic-outcome-panel__table .rt-thead.-headerGroups {
  border-bottom: none; }
  .strategic-outcome-panel__table .rt-thead.-headerGroups .rt-th {
    justify-content: center;
    padding: 0; }
  .strategic-outcome-panel__table .rt-thead.-headerGroups .border-bottom {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1); }
  .strategic-outcome-panel__table .rt-thead.-headerGroups .two-rows-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95px;
    justify-content: center;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    border-left: solid 1px rgba(0, 0, 0, 0.1); }
    .strategic-outcome-panel__table .rt-thead.-headerGroups .two-rows-title div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .strategic-outcome-panel__table .rt-thead.-headerGroups .two-rows-title :first-child {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1); }

.strategic-outcome-panel__table .rt-th.left-border {
  border-left: solid 1px rgba(0, 0, 0, 0.1); }

.strategic-outcome-panel__table .rt-th.right-border {
  border-right: solid 1px rgba(0, 0, 0, 0.1); }

.strategic-outcome-panel__table .rt-thead, .strategic-outcome-panel__table .rt-thead.-headerGroups {
  background-color: #EFF2F5; }

.strategic-outcome-panel__table .two-rows-cell__percent {
  color: #5a6872; }

.strategic-outcome-panel__table .title-column__label {
  display: flex;
  flex-direction: column; }
  .strategic-outcome-panel__table .title-column__label .label {
    display: flex;
    flex-direction: row;
    color: #031c2d;
    font-size: 16px;
    font-weight: 600;
    height: 25px;
    align-items: center; }
    .strategic-outcome-panel__table .title-column__label .label .info-indicator {
      margin-left: 10px; }
    .strategic-outcome-panel__table .title-column__label .label .square-indicator {
      height: 16px;
      width: 16px;
      margin-right: 10px; }
  .strategic-outcome-panel__table .title-column__label .sub_title {
    margin-left: 26px;
    font-size: 14px;
    display: flex;
    flex-direction: column; }

.strategic-outcome-panel__table .rt-noData {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex; }

.adjusted-beneficiaries-panel__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 30px; }

.kpi-1__content, .kpi-2__content, .kpi-3__content {
  margin-top: 30px; }

.kpi-2__content {
  display: flex;
  flex-direction: column; }

.kpi-2__chart-title {
  color: #031c2d;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center; }

.kpi-1 {
  margin-top: 20px; }
  .kpi-1__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 10px;
    margin-top: 0; }
  .kpi-1__chart-wrap:first-child {
    border-right: 2px solid #eff2f5; }
  .kpi-1__chart-wrap {
    padding: 0 10px;
    display: flex;
    flex-direction: column; }
  .kpi-1__chart-title {
    font-size: 18px;
    font-weight: 600;
    color: #031c2d;
    min-height: 50px; }
    .kpi-1__chart-title .sub-title {
      font-size: 12px;
      color: #8c9ba5; }

@media screen and (max-width: 768px) {
  .kpi-1__content {
    grid-template-columns: 1fr; }
  .kpi-1__chart-wrap:first-child {
    border-right: none; } }

.header-bottom-indicators {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-column-gap: 5px;
  grid-row-gap: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  background-color: #031c2d;
  padding: 20px 5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.header-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.resources-panel__inner {
  background: #fff;
  padding-right: 25px; }

.resources-panel .chart-indicator-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 2px;
  background: #fff; }
  .resources-panel .chart-indicator-panel__bottom {
    padding-left: 25px;
    background: #fff; }
  .resources-panel .chart-indicator-panel .indicators__container {
    grid-template-columns: initial; }
    .resources-panel .chart-indicator-panel .indicators__container .bar-indicator-row__desc {
      width: initial; }
    .resources-panel .chart-indicator-panel .indicators__container .indicators-annual {
      margin-bottom: 20px; }
  .resources-panel .chart-indicator-panel .legend-wrap {
    width: 310px;
    margin-top: 30px;
    align-self: center; }

.resources-panel .chart.center {
  align-items: flex-start;
  width: 100%;
  max-width: initial; }

.resources-panel .chart__container {
  flex-direction: column;
  width: 100%;
  display: flex; }

.resources-panel .chart .chart-wrap {
  display: flex;
  flex-direction: column; }
  .resources-panel .chart .chart-wrap .chart-v2 {
    align-self: center;
    flex-wrap: wrap;
    margin-top: -25px; }

.resources-panel .bar-indicator-row__desc {
  min-width: 175px; }

.resources-panel .bar-indicator-row__item-label {
  width: 170px; }

.resources-panel .chart.center > div {
  width: 100%; }

.resources-panel .bar-indicator__value.breakdown {
  color: var(--interactive-01); }
  .resources-panel .bar-indicator__value.breakdown .value, .resources-panel .bar-indicator__value.breakdown .value-symbol {
    text-decoration: underline; }

@media screen and (max-width: 1050px) {
  .resources-panel .chart-indicator-panel {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); } }

@media screen and (max-width: 776px) {
  .resources-panel__inner {
    padding-right: 0;
    padding-bottom: 15px; }
  .resources-panel .chart-indicator-panel__bottom {
    padding-top: 15px;
    padding-left: 0; }
  .resources-panel .chart-indicator-panel {
    grid-template-columns: 1fr; }
    .resources-panel .chart-indicator-panel .legend-wrap {
      width: 100%; } }

.donors-bars__forecasted, .donors-bars__confirmed {
  flex-grow: 1;
  background-color: white; }

.donors-bars__confirmed {
  padding-right: 25px; }

.donors-bars__forecasted {
  padding-left: 25px; }

.donors-bars__container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 2px;
  background-color: #E1E7EB; }
  .donors-bars__container .bar-indicator-row__desc {
    width: 200px; }
  .donors-bars__container .no-data {
    background-color: white; }
  .donors-bars__container .indicators-row__subtitle .tooltip {
    position: relative;
    width: auto;
    color: var(--interactive-01);
    text-decoration: underline; }

.donors-legend__container {
  margin-top: 20px; }
  .donors-legend__container .legend .mark-wrap {
    margin-right: 12px; }

@media (max-width: 876px) {
  .donors-bars__confirmed {
    padding-right: 0;
    padding-bottom: 25px; }
  .donors-bars__forecasted {
    padding-left: 0;
    padding-top: 25px; } }

@media (max-width: 500px) {
  .donors-bars__container {
    grid-template-columns: repeat(auto-fit, minmax(246px, 1fr)); } }

@media (max-width: 351px) {
  .donors-bars__container .bar-indicator-row__desc {
    width: 145px; } }

.focus-areas .legend {
  margin-left: 5px; }

.focus-areas__disclaimer {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 3px;
  margin-bottom: 24px;
  color: #5a6872;
  font-weight: 600; }
  .focus-areas__disclaimer-title {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: #031c2d; }

.focus-areas-charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.focus-areas-chart {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 5px; }
  .focus-areas-chart .chart-v2 {
    margin-top: -25px; }
  .focus-areas-chart__container {
    flex-grow: 1;
    max-width: 290px; }
  .focus-areas-chart .chart__title {
    font-size: 16px;
    font-weight: 600;
    color: #000; }
  .focus-areas-chart .chart-v2 {
    justify-content: center;
    flex-direction: row; }
    .focus-areas-chart .chart-v2 .doughnut-chart .center-text {
      font-size: 16px; }
  .focus-areas-chart .desc {
    margin-top: 20px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px; }
    .focus-areas-chart .desc__value {
      padding-right: 10px;
      line-height: normal;
      letter-spacing: normal;
      white-space: nowrap;
      justify-self: center;
      align-self: center;
      text-align: right;
      min-width: 40px; }
    .focus-areas-chart .desc__values {
      display: flex; }
    .focus-areas-chart .desc__label {
      font-weight: 600; }
    .focus-areas-chart .desc .desc__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px; }

.food-distribution-panel .bar-indicator__value {
  min-width: 75px; }

.food-distribution-panel .bar-indicator .value-suffix {
  margin-right: 0; }

.food-distribution-panel__disclaimer {
  margin-top: 10px; }
  .food-distribution-panel__disclaimer .label, .food-distribution-panel__disclaimer .body {
    color: #5A6872;
    font-size: 13px;
    line-height: 22px;
    font-family: "Open Sans", sans-serif; }
  .food-distribution-panel__disclaimer .label {
    font-weight: 600; }

.partners-panel-content .legend {
  margin-top: 20px; }

.partners-panel-content .chart {
  flex: 1;
  padding-right: 19px; }
  .partners-panel-content .chart__title {
    width: 100%; }

.partners-panel-content .doughnut-chart {
  margin-top: -25px; }

.partners-panel-content .chart-indicator-panel {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 2px;
  background-color: #E1E7EB;
  flex-wrap: wrap; }
  .partners-panel-content .chart-indicator-panel__bottom {
    margin-top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
    background-color: #E1E7EB; }
    .partners-panel-content .chart-indicator-panel__bottom .top-grouped-indicators {
      background: #fff; }
      .partners-panel-content .chart-indicator-panel__bottom .top-grouped-indicators .bar-indicator-row__desc {
        min-width: 180px; }
      .partners-panel-content .chart-indicator-panel__bottom .top-grouped-indicators .bar-indicator__percents {
        min-width: 50px !important; }

.partners-panel-content .partners-panel-content__inner {
  flex: 1;
  background: #fff; }
  .partners-panel-content .partners-panel-content__inner .doughnut-chart__container {
    justify-content: center;
    display: flex; }
  .partners-panel-content .partners-panel-content__inner .chart {
    flex: 1; }

.partners-panel-content__indicators {
  display: flex;
  justify-content: space-around; }

.partners-panel-content .top-grouped-indicators {
  padding: 0 15px; }

.partners-panel-content .bar-indicator-row__desc {
  width: 130px; }

.partners-panel-content__content {
  position: relative; }

.partners-panel-content .chart-indicator-panel__top {
  justify-content: center; }

.partners-panel-content .chart-indicator-panel__bottom {
  min-height: 180px;
  flex: 2; }
  .partners-panel-content .chart-indicator-panel__bottom .title__subtitle {
    margin-top: 5px;
    margin-bottom: 10px; }
    .partners-panel-content .chart-indicator-panel__bottom .title__subtitle .tooltip {
      position: relative;
      width: auto;
      color: var(--interactive-01);
      text-decoration: underline; }

.partners-panel-content .grouped-indicators {
  flex-grow: 1; }

@media (min-width: 1024px) and (max-width: 1150px) {
  .partners-panel-content.chart-indicator-panel {
    grid-template-columns: 1.1fr 2fr; }
    .partners-panel-content.chart-indicator-panel .chart-indicator-panel__bottom .top-grouped-indicators .bar-indicator-row__desc {
      min-width: 145px; } }

@media (max-width: 1024px) {
  .partners-panel-content .chart {
    padding-right: 0; }
  .partners-panel-content .partners-panel-content__inner {
    padding-bottom: 15px; }
  .partners-panel-content .chart-indicator-panel {
    grid-template-columns: 1fr; }
  .partners-panel-content .chart-indicator-panel__bottom {
    grid-template-columns: 1fr; }
    .partners-panel-content .chart-indicator-panel__bottom .top-grouped-indicators {
      padding: 10px 0 5px 0; } }

@media (max-width: 400px) {
  .partners-panel-content .legend__label {
    flex-direction: column; }
  .partners-panel-content .chart-indicator-panel__bottom .top-grouped-indicators .bar-indicator-row__desc {
    min-width: 145px; } }

.beneficiaries-panel .programme-areas-region-country {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #E1E7EB; }
  .beneficiaries-panel .programme-areas-region-country .beneficiaries-panel__legend {
    margin-top: 20px; }

.beneficiaries-panel__main-content {
  padding-right: 10px;
  padding-bottom: 15px;
  background: #fff; }

.beneficiaries-panel__wrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2px;
  background-color: #E1E7EB; }
  .beneficiaries-panel__wrap .programme-area-chart, .beneficiaries-panel__wrap .projected-charts {
    background-color: #FFFFFF; }
  .beneficiaries-panel__wrap .programme-area-chart {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 20px; }
    .beneficiaries-panel__wrap .programme-area-chart .wrap {
      width: 100%; }
      .beneficiaries-panel__wrap .programme-area-chart .wrap .indicators {
        margin-top: 10px;
        margin-bottom: 20px; }
        .beneficiaries-panel__wrap .programme-area-chart .wrap .indicators .bar-indicator-row__container .tooltip {
          width: 70%; }
  .beneficiaries-panel__wrap .modality-chart {
    background: #fff; }
    .beneficiaries-panel__wrap .modality-chart .beneficiaries-panel__legend {
      margin-top: 20px; }
  .beneficiaries-panel__wrap .projected-charts {
    padding-left: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding-bottom: 15px; }
    .beneficiaries-panel__wrap .projected-charts .legend {
      margin-right: 0; }
    .beneficiaries-panel__wrap .projected-charts__residence-status, .beneficiaries-panel__wrap .projected-charts__by-region {
      width: 100%;
      margin-bottom: 20px; }
      .beneficiaries-panel__wrap .projected-charts__residence-status .bar-indicator-row__item, .beneficiaries-panel__wrap .projected-charts__residence-status .bar-indicator__value, .beneficiaries-panel__wrap .projected-charts__by-region .bar-indicator-row__item, .beneficiaries-panel__wrap .projected-charts__by-region .bar-indicator__value {
        color: #031c2d;
        font-size: 14px;
        font-weight: 400; }
      .beneficiaries-panel__wrap .projected-charts__residence-status .bar-indicator-row__desc, .beneficiaries-panel__wrap .projected-charts__by-region .bar-indicator-row__desc {
        width: 90px; }

.beneficiaries-panel__legend {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end; }
  .beneficiaries-panel__legend .dash-legend {
    margin-top: 0;
    margin-right: 10px; }
  .beneficiaries-panel__legend .legend {
    margin-right: 10px; }

.beneficiaries-panel .title__subtitle {
  margin-top: 10px; }

.beneficiaries-panel .bar-indicator-row__desc {
  width: 340px; }
  .beneficiaries-panel .bar-indicator-row__desc .bar-indicator-row__item-label {
    width: initial; }

.beneficiaries-panel .beneficiaries-total-section {
  border-bottom: 1px solid var(--ui-04);
  margin-bottom: 15px; }
  .beneficiaries-panel .beneficiaries-total-section__indicators {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 15px 0 20px 0; }
    .beneficiaries-panel .beneficiaries-total-section__indicators .legend .label, .beneficiaries-panel .beneficiaries-total-section__indicators .dash-legend__label {
      font-size: 14px;
      font-weight: 700;
      color: #031c2d; }
    .beneficiaries-panel .beneficiaries-total-section__indicators .dash-legend__label {
      margin-bottom: 2px; }

@media only screen and (max-width: 1150px) {
  .beneficiaries-panel__wrap {
    grid-template-rows: 1fr;
    grid-template-columns: none; }
    .beneficiaries-panel__wrap .programme-area-chart {
      padding-right: 0;
      padding-bottom: 20px; }
    .beneficiaries-panel__wrap .projected-charts {
      padding-left: 0;
      padding-top: 20px; } }

@media (max-width: 882px) {
  .beneficiaries-panel .bar-indicator-row__desc {
    width: 250px; } }

@media (max-width: 476px) {
  .beneficiaries-panel .bar-indicator-row__desc {
    width: 180px; }
  .beneficiaries-panel .legend .mark-wrap {
    margin-bottom: 5px; } }

.top-countries-panel .chart-indicator-panel {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 2px;
  background-color: #E1E7EB;
  flex-wrap: wrap; }
  .top-countries-panel .chart-indicator-panel .indicators .title__subtitle .tooltip {
    position: relative;
    width: auto;
    color: var(--interactive-01);
    text-decoration: underline; }
  .top-countries-panel .chart-indicator-panel .title {
    display: flex;
    flex-direction: column;
    line-height: 26px;
    font-size: 16px;
    font-weight: 600; }
    .top-countries-panel .chart-indicator-panel .title__subtitle {
      margin-bottom: 5px;
      line-height: 23px;
      font-size: 14px;
      font-weight: 600;
      color: #5a6872; }

.top-countries-panel .chart-indicator-panel__bottom {
  padding-left: 15px;
  background: #fff; }
  .top-countries-panel .chart-indicator-panel__bottom .bar-indicator-row__desc {
    width: initial;
    min-width: initial; }

.top-countries-panel__inner {
  padding-right: 15px;
  background: #fff; }
  .top-countries-panel__inner .chart {
    width: 100%;
    margin: 0; }
    .top-countries-panel__inner .chart .legend {
      margin-top: 20px; }
  .top-countries-panel__inner .doughnut-chart__container {
    margin-top: -20px;
    align-self: center; }

.top-countries-panel .legend-wrap {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 5px; }
  .top-countries-panel .legend-wrap .dash-legend {
    margin-top: 0; }

@media (max-width: 1000px) {
  .top-countries-panel .chart-indicator-panel {
    grid-template-columns: 1fr 1fr; } }

@media (max-width: 700px) {
  .top-countries-panel .chart-indicator-panel {
    grid-template-columns: 1fr; }
  .top-countries-panel .chart-indicator-panel__bottom {
    padding: 15px 0 0 0; }
  .top-countries-panel__inner {
    padding: 0 0 15px 0; } }

.regional-bureaus-panel .doughnut-chart {
  margin-top: -30px; }

.regional-bureaus-panel-charts__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
  background: #E1E7EB; }

.regional-bureaus-panel .legend {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  max-height: 80px; }
  .regional-bureaus-panel .legend .mark-wrap {
    width: 165px; }
  .regional-bureaus-panel .legend .value {
    width: 60px; }

.regional-bureaus-panel-chart {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 0 15px; }
  .regional-bureaus-panel-chart .chart-v2 {
    align-self: center; }

.regional-bureaus-panel-chart:first-child {
  padding-left: 0; }

.regional-bureaus-panel-chart:last-child {
  padding-right: 0; }

@media (max-width: 1120px) and (min-width: 941px) {
  .regional-bureaus-panel .legend {
    max-height: initial; }
    .regional-bureaus-panel .legend .mark-wrap {
      width: 250px; }
  .regional-bureaus-panel-chart .title {
    min-height: 55px; } }

@media (max-width: 940px) {
  .regional-bureaus-panel-charts__container {
    grid-template-columns: 1fr; }
    .regional-bureaus-panel-charts__container .regional-bureaus-panel-chart {
      padding-top: 20px;
      padding-left: 0;
      padding-right: 0; }
    .regional-bureaus-panel-charts__container .regional-bureaus-panel-chart:first-child {
      padding-bottom: 20px;
      padding-top: 0; } }

@media (max-width: 515px) {
  .regional-bureaus-panel .legend {
    max-height: initial; }
    .regional-bureaus-panel .legend .mark-wrap {
      width: 250px; } }

.output-indicators-cards {
  display: none;
  flex-wrap: wrap; }

@media (max-width: 950px) {
  .output-indicators .wfp--table {
    display: none; }
  .output-indicators-cards {
    display: flex; } }

.local-economies-panel {
  display: flex;
  flex-direction: column; }
  .local-economies-panel .chart, .local-economies-panel .chart__container {
    width: 100%;
    max-width: none; }
  .local-economies-panel .legend {
    margin-top: 20px;
    width: 350px;
    align-self: center; }
    .local-economies-panel .legend .tooltip {
      position: relative;
      color: var(--interactive-01);
      text-decoration: underline; }
    .local-economies-panel .legend .value-container {
      margin-left: 10px; }
      .local-economies-panel .legend .value-container .percents {
        margin-left: 8px; }
  .local-economies-panel .disclaimer {
    font-size: 12px;
    line-height: 23px;
    color: #5a6872;
    font-weight: 600;
    text-align: left;
    font-style: normal; }
  .local-economies-panel .chart-v2 {
    justify-content: center;
    margin-top: -30px; }
  .local-economies-panel .doughnut-chart__container {
    display: flex;
    flex-direction: column; }

.projected-requirements-panel .indicators-focus-area {
  margin-bottom: 20px; }

.projected-requirements-panel .bar-indicator-row__item-label {
  width: 210px; }

.projected-requirements-panel .bar-indicator-row__desc {
  width: 100%; }

@media screen and (max-width: 776px) {
  .projected-requirements-panel .bar-indicator-row__item-label {
    width: 190px; } }

.indicator {
  display: flex; }
  .indicator__value {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    white-space: nowrap;
    font-size: 46px; }
    .indicator__value .symbol-after {
      font-size: 25px; }
    .indicator__value .symbol-before {
      font-size: 14px; }
  .indicator__labels.center {
    align-self: center; }
  .indicator__label {
    font-size: 16px;
    text-align: left; }
  .indicator__label.bold {
    font-weight: bold; }
  .indicator__second-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: left; }
  .indicator__gray-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    text-align: left;
    color: white; }

.indicator.horizontal {
  flex-direction: row; }
  .indicator.horizontal .indicator__value {
    padding-right: 10px; }

.indicator.vertical {
  flex-direction: column; }

.indicator.center {
  align-self: center;
  justify-self: center;
  text-align: center; }

.indicator.hide {
  display: none; }

.director-indicator {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 8px; }
  .director-indicator__logo {
    width: 60px;
    height: 60px;
    border-radius: 50%; }
    .director-indicator__logo .wfp--user__icon--empty {
      width: 100%;
      height: 100%;
      border: none;
      overflow: auto !important;
      border-radius: 50%;
			background-size: 100%;
			background-color: #818d94;
    }
    .director-indicator__logo .wfp--user__icon {
      margin-right: 0; }
  .director-indicator__info {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .director-indicator__info .title {
      font-weight: bold; }

.deputy-director-title {
  color: #F7B825;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 5px;
  font-weight: 700; }

.deputy-director {
  margin-bottom: 5px; }
  .deputy-director .title {
    display: none; }
  .deputy-director .director-indicator__logo {
    width: 30px;
    height: 30px; }

.deputy-director:last-child {
  margin-bottom: 13px; }

.header-list-indicators {
  background-color: #031c2d;
  padding-bottom: 20px; }

.header-list-indicators.top-border {
  border-top: 1px solid rgba(255, 255, 255, 0.22); }

.header-list-indicators.empty {
  padding: 20px; }

.percent-progress-indicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px; }
  .percent-progress-indicator__label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 4px;
    position: relative; }
  .percent-progress-indicator__label.dark {
    color: #4997AE; }
  .percent-progress-indicator__label.light {
    color: white; }
  .percent-progress-indicator__label.hide {
    display: none; }
  .percent-progress-indicator .progress-symbol {
    font-size: 14px;
    color: #031c2d;
    white-space: nowrap;
    min-width: 70px; }
  .percent-progress-indicator .progress-symbol.hide {
    display: none; }

.indicator-arrow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 80px; }
  .indicator-arrow__icon {
    cursor: pointer; }
  .indicator-arrow__percent {
    padding-left: 2px;
    font-size: 14px;
    min-width: 30px; }

.info-indicator {
  cursor: pointer;
  display: flex;
  align-items: center; }
  .info-indicator .hover-info {
    height: 18px;
    max-width: 250px; }
    .info-indicator .hover-info .info-link {
      font-size: 12px;
      color: #0a6eb4;
      text-decoration: underline; }

.tooltip-info {
  line-height: normal;
  z-index: 100; }

div[x-placement='top'] .arrow-regular {
  border-top: 7px solid #031c2d; }

div[x-placement='bottom'] .arrow-regular {
  border-bottom: 7px solid #031c2d; }

.tippy-tooltip.dark-theme {
  background-color: #031C2D;
  border: 1px solid #1A3748; }
  .tippy-tooltip.dark-theme .breakdown-label, .tippy-tooltip.dark-theme .bar-tooltip__title {
    color: #F7B825; }

.xlsx-export {
  display: flex;
  align-items: center; }
  .xlsx-export__title {
    font-size: 12px;
    font-weight: 600; }
  .xlsx-export__subitem {
    display: flex; }
    .xlsx-export__subitem__value {
      font-size: 14px;
      color: #0a6eb4;
      cursor: pointer;
      font-weight: 700;
      margin-right: 4px;
      padding: 0; }
    .xlsx-export__subitem .wfp--inline-loading {
      width: initial; }
    .xlsx-export__subitem__value:hover {
      text-decoration: underline; }
    .xlsx-export__subitem__value:disabled {
      color: #8C9BA5;
      cursor: initial; }
      .xlsx-export__subitem__value:disabled:hover {
        text-decoration: none; }
  .xlsx-export__item {
    display: flex; }
    .xlsx-export__item__value {
      font-size: 14px;
      color: #0a6eb4;
      cursor: pointer;
      font-weight: 700;
      margin-left: 4px; }
    .xlsx-export__item__value span:hover {
      text-decoration: underline; }
    .xlsx-export__item__value:disabled {
      color: #8C9BA5;
      cursor: initial; }
      .xlsx-export__item__value:disabled span:hover {
        text-decoration: none; }

.wasting-indicators {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .wasting-indicators__switcher {
    cursor: pointer;
    width: 100%; }
  .wasting-indicators__options {
    display: flex;
    flex-direction: column; }
    .wasting-indicators__options div {
      cursor: pointer;
      border-bottom: 1px solid #EFF2F5;
      color: #0b77c2;
      padding: 10px 0; }
  .wasting-indicators .percent-progress-indicator__label > .left:after {
    font-family: 'FontAwesome', sans-serif;
    content: "\\f107";
    padding: 5px;
    color: white; }

.top-counties-indicator {
  display: flex;
  flex-direction: column; }
  .top-counties-indicator__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px; }
  .top-counties-indicator__items {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
  .top-counties-indicator__items .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 25px; }
    .top-counties-indicator__items .item .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px 5px 0; }
      .top-counties-indicator__items .item .title .name {
        text-decoration: none;
        font-size: 12px;
        font-weight: normal;
        color: white;
        max-width: 190px;
        line-height: 1.3; }
      .top-counties-indicator__items .item .title .crisis-level {
        margin-left: 5px;
        height: 16px;
        width: 30px;
        min-width: 30px;
        text-decoration: none;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.9;
        border-radius: 14px;
        color: white;
        text-align: center;
        text-transform: capitalize;
        border-bottom: none; }
    .top-counties-indicator__items .item .value {
      font-size: 12px;
      font-weight: normal;
      color: white;
      align-self: flex-start; }
  .top-counties-indicator .info-indicator {
    padding-right: 5px;
    flex-shrink: 0; }

@media only screen and (max-width: 991px) {
  .top-counties-indicator .info-indicator {
    margin-right: 0; } }

.one-line-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }
  .one-line-indicator .label {
    font-weight: bold;
    min-width: 50%; }
  .one-line-indicator .value {
    font-weight: normal; }
    .one-line-indicator .value p {
      text-align: right;
      white-space: nowrap; }
  .one-line-indicator .up {
    color: #8ad220;
    font-weight: bold; }
  .one-line-indicator .up::after {
    content: "↑";
    font-size: 22px; }
  .one-line-indicator .down {
    color: #c5192d;
    font-weight: bold; }
  .one-line-indicator .down::after {
    content: "↓";
    font-size: 22px; }

.bar-indicator, .compare-bar-indicator {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative; }
  .bar-indicator__scale-container, .compare-bar-indicator__scale-container {
    justify-self: center;
    align-self: center;
    display: flex;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 2%; }
  .bar-indicator__prev, .compare-bar-indicator__prev {
    height: 24px;
    position: absolute;
    top: -6px;
    border-left-width: 2px;
    border-left-color: #000000;
    z-index: 1; }
  .bar-indicator__third, .compare-bar-indicator__third {
    top: 3px;
    height: 6px;
    background-color: #9deaff;
    border-radius: 9px;
    position: absolute; }
  .bar-indicator__scale-wrap, .compare-bar-indicator__scale-wrap {
    position: relative;
    width: 100%;
    height: 12px; }
    .bar-indicator__scale-wrap .scale, .compare-bar-indicator__scale-wrap .scale {
      height: 12px;
      background-color: #0780C0;
      justify-self: center;
      align-self: center; }
    .bar-indicator__scale-wrap .label, .compare-bar-indicator__scale-wrap .label {
      font-size: 12px;
      color: #031c2d;
      margin-left: 5px;
      white-space: nowrap; }
    .bar-indicator__scale-wrap .scale.single, .compare-bar-indicator__scale-wrap .scale.single {
      width: 100%; }
    .bar-indicator__scale-wrap .scale:nth-child(2), .compare-bar-indicator__scale-wrap .scale:nth-child(2) {
      background-color: #73C3FE; }
    .bar-indicator__scale-wrap .scale:nth-child(3), .compare-bar-indicator__scale-wrap .scale:nth-child(3) {
      background-color: #9deaff; }
    .bar-indicator__scale-wrap .scale:nth-child(4), .compare-bar-indicator__scale-wrap .scale:nth-child(4) {
      background-color: #C8EEF7; }
    .bar-indicator__scale-wrap .scale:nth-child(5), .compare-bar-indicator__scale-wrap .scale:nth-child(5) {
      background-color: #D6DDED; }
  .bar-indicator .value-suffix, .compare-bar-indicator .value-suffix {
    font-size: 14px;
    margin-right: 5px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }
  .bar-indicator__value, .compare-bar-indicator__value {
    padding-right: 10px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    justify-self: center;
    align-self: center;
    text-align: right;
    min-width: 65px;
    font-family: 'Open Sans', sans-serif; }
    .bar-indicator__value .value-symbol, .compare-bar-indicator__value .value-symbol {
      font-weight: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal; }
  .bar-indicator__percents, .compare-bar-indicator__percents {
    min-width: 55px; }

.bar-indicator.dark, .compare-bar-indicator.dark {
  color: #031c2d; }

.bar-indicator.white, .compare-bar-indicator.white {
  color: white; }

.compare-bar-indicator__scales {
  position: relative;
  width: 100%;
  display: flex !important;
  align-items: center; }
  .compare-bar-indicator__scales .bar {
    position: absolute;
    height: 10px;
    border: solid 1px #4997AE;
    background-color: #4997AE;
    border-radius: 9px; }
  .compare-bar-indicator__scales .line {
    position: absolute;
    height: 24px;
    border-right: 2px solid #031c2d; }
  .compare-bar-indicator__scales .tooltip {
    z-index: 1;
    height: 10px; }

.bar-tooltip__title {
  font-weight: 700;
  padding-bottom: 10px;
  min-width: 150px; }

.bar-tooltip__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px; }
  .bar-tooltip__item .year {
    color: #8c9ba5;
    padding-right: 10px; }
  .bar-tooltip__item .label {
    padding-right: 10px;
    font-weight: 600; }
  .bar-tooltip__item .mark {
    border-radius: 22px;
    margin-right: 7px;
    width: 16px;
    height: 16px; }

.bar-tooltip .extra-data-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 25px; }
  .bar-tooltip .extra-data-wrap__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px; }
    .bar-tooltip .extra-data-wrap__item .value {
      margin-left: 10px; }

.bar-tooltip__item.horizontal .value {
  margin-left: auto; }

.bar-tooltip div:nth-child(2) .mark {
  background-color: #0780C0; }

.bar-tooltip div:nth-child(3) .mark {
  background-color: #73C3FE; }

.bar-tooltip div:nth-child(4) .mark {
  background-color: #9deaff; }

.bar-tooltip div:nth-child(5) .mark {
  background-color: #C8EEF7; }

.bar-tooltip div:nth-child(6) .mark {
  background-color: #D6DDED; }

.bar-indicator-row__container {
  display: flex;
  flex-basis: 45%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 32px; }
  .bar-indicator-row__container .tooltip {
    flex: 1;
    cursor: pointer;
    position: initial;
    display: flex;
    align-items: center; }

.bar-indicator-row__main-content {
  display: flex;
  overflow: hidden; }

.bar-indicator-row__desc {
  width: 47%;
  min-width: 142px;
  position: relative;
  margin-right: 3%;
  overflow-x: hidden;
  display: flex;
  align-items: center; }

.bar-indicator-row__item {
  margin-right: 5px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap; }

.bar-indicator-row__item.order {
  overflow-x: visible;
  margin-bottom: 0;
  width: 20px; }

.bar-indicator-row__item-label {
  width: 160px; }

.bar-indicator-row__item-label-tooltip {
  position: absolute;
  top: -25px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: 300;
  color: #031c2d;
  background: #eeeeee;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap; }

.indicators__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-column-gap: 20px; }

.indicators-row__title {
  font-size: 16px;
  line-height: 26px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }
  .indicators-row__title__container {
    margin-bottom: 12px; }

.indicators-row__subtitle {
  font-size: 14px;
  line-height: 23px;
  font-family: "Open Sans", sans-serif;
  color: #5a6872;
  font-weight: 600; }

@media (max-width: 400px) {
  .bar-indicator__percents {
    display: none; } }

.bar-indicator-column {
  margin: 5px 0 18px 0; }
  .bar-indicator-column .bar-indicator__scale-wrap {
    position: relative; }
  .bar-indicator-column .bar-indicator__scale-container {
    border-radius: 0;
    overflow: visible;
    align-items: center;
    margin-right: 0; }
  .bar-indicator-column__label {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 600; }
  .bar-indicator-column .current-value {
    font-weight: 600;
    text-decoration: underline;
    color: var(--interactive-01); }
  .bar-indicator-column .scale.single {
    border-radius: 0 10px 10px 0; }

.big-percent-indicator {
  display: flex;
  flex-direction: column; }
  .big-percent-indicator__label {
    font-size: 16px;
    font-weight: 600;
    color: #031c2d;
    margin-bottom: 15px; }
  .big-percent-indicator__bar {
    height: 25px;
    width: 100%;
    position: relative; }
    .big-percent-indicator__bar .right-bar, .big-percent-indicator__bar .left-bar {
      height: 100%;
      width: 100%;
      border-radius: 25px; }
    .big-percent-indicator__bar .left-bar {
      background-color: #4997AE;
      color: white;
      font-size: 16px;
      font-weight: 600;
      text-align: right;
      position: absolute;
      z-index: 5; }
      .big-percent-indicator__bar .left-bar .percent-text {
        padding-right: 8px; }
    .big-percent-indicator__bar .right-bar {
      width: 100%;
      background-color: #EFF2F5;
      position: absolute; }
  .big-percent-indicator__sub-labels {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 15px; }
    .big-percent-indicator__sub-labels .left .label, .big-percent-indicator__sub-labels .right .label {
      font-size: 14px;
      font-weight: 600;
      color: black;
      display: flex;
      flex-direction: row;
      align-items: center; }
    .big-percent-indicator__sub-labels .left .value.no-da, .big-percent-indicator__sub-labels .right .value.no-da {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      color: #5a6872; }
    .big-percent-indicator__sub-labels .left .value, .big-percent-indicator__sub-labels .right .value {
      margin-left: 22px;
      font-size: 16px;
      font-weight: normal;
      color: #031c2d;
      display: flex;
      flex-direction: row; }
      .big-percent-indicator__sub-labels .left .value .info-indicator, .big-percent-indicator__sub-labels .right .value .info-indicator {
        margin-left: 10px; }
    .big-percent-indicator__sub-labels .left .square-indicator, .big-percent-indicator__sub-labels .right .square-indicator {
      width: 14px;
      height: 14px;
      margin-right: 8px; }
    .big-percent-indicator__sub-labels .left .square-indicator {
      background-color: #4997AE; }
    .big-percent-indicator__sub-labels .right .square-indicator {
      background-color: #EFF2F5; }

.horizontal-indicator {
	width: 100%;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1;
}
.horizontal-indicator__main-content {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}
.horizontal-indicator__extra-content {
	font-size: 15px;
	font-weight: 400;
	margin-top: 6px;
}
.horizontal-indicator__extra-content .item {
	display: flex;
	flex-direction: row;
	flex-basis: 100%;
}
.horizontal-indicator__extra-content .value {
	font-weight: 700;
	margin-bottom: 5px;
	margin-right: 10px;
}
.horizontal-indicator b {
	font-weight: 700;
}
.horizontal-indicator__values {
	margin-right: 10px;
}
.horizontal-indicator__values .value {
	font-size: 46px;
}
.horizontal-indicator__values .value .symbol {
	font-size: 25px;
}
.horizontal-indicator__values .value .disclaimer-icon {
	font-size: 16px;
	position: absolute;
	bottom: 30px;
}
.horizontal-indicator__values .currency {
	font-size: 14px;
}
.horizontal-indicator__labels .label {
	font-size: 16px;
	line-height: 1.25;
	display: flex;
	align-items: flex-end;
}
.horizontal-indicator__labels .sub-label-big {
	font-weight: 400;
	font-size: 15px;
	white-space: nowrap;
	padding-bottom: 5px;
}
.horizontal-indicator__labels .sub-label {
	font-weight: 400;
	font-size: 15px;
	white-space: nowrap;
	padding-bottom: 5px;
}

.horizontal-indicator-multiple {
	width: 100%;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1;
}
.horizontal-indicator-multiple b {
  font-weight: 700;
}
.horizontal-indicator-multiple__main-content {
	flex-direction: row;
	display: flex;
	align-items: flex-end;
	padding-bottom: 5px;
}
.horizontal-indicator-multiple__values {
  align-self: flex-start;
	margin-right: 10px;
}
.horizontal-indicator-multiple__values .tooltip {
	position: relative;
	display: block !important;
}
.horizontal-indicator-multiple__values .tooltip .value {
	border-bottom: 1px solid white;
}
.horizontal-indicator-multiple__values .value {
	font-size: 46px;
}
.horizontal-indicator-multiple__values .value .symbol {
	font-size: 25px;
}
.horizontal-indicator-multiple__values .currency {
	font-size: 14px;
}
.horizontal-indicator-multiple__labels {
	width: auto !important;
}
.horizontal-indicator-multiple__labels .label {
	font-size: 16px;
	line-height: 1.25;
	align-items: flex-end;
	display: inline-block;
}

.horizontal-indicator-multiple__labels .label .value {
  padding-right: 85px;
}

.horizontal-indicator-multiple__labels .label .value-big {
  padding-right: 55px;
}

.horizontal-indicator-multiple__labels .label .tooltip {
	position: relative;
}
.horizontal-indicator-multiple__labels .label::first-letter {
	text-transform: uppercase;
}
.horizontal-indicator-multiple__labels .year {
	font-weight: 400;
	font-size: 15px;
	padding-bottom: 5px;
}
.horizontal-indicator-multiple__beneficiaries {
	flex-basis: 100%;
	font-size: 15px;
	margin-top: 5px;
	display: inline;
}
.horizontal-indicator-multiple__beneficiaries .tooltip {
	position: relative;
}
.horizontal-indicator-multiple__beneficiaries .tooltip .value {
	border-bottom: 1px solid white;
	display: inline;
}
.horizontal-indicator-multiple__beneficiaries .value {
	margin-bottom: 5px;
}
.horizontal-indicator-multiple__beneficiaries .year {
	font-weight: 400;
	padding: 0 5px;
}
.horizontal-indicator-multiple__beneficiaries .label {
	font-weight: 400;
}

.tooltip-content-msg {
	line-height: 1.2;
}

.header-wrap .employees-indicators {
  min-width: 220px;
  background-color: #031c2d;
  padding-top: 20px; }
  .header-wrap .employees-indicators .one-line-indicator.total .value, .header-wrap .employees-indicators .one-line-indicator.total .label {
    font-weight: 700; }
  .header-wrap .employees-indicators .one-line-indicator .label {
    font-weight: 700; }
  .header-wrap .employees-indicators .one-line-indicator .value {
    font-weight: 400; }
  .header-wrap .employees-indicators .sub-indicators {
    display: flex;
    flex-flow: row wrap;
    font-weight: normal;
    font-size: 12px; }
  .header-wrap .employees-indicators__indicator {
    margin-top: 10px; }

.offices-indicators {
  margin-top: 15px; }
  .offices-indicators__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .offices-indicators__item {
    line-height: 25px;
    padding-right: 5px; }
  .offices-indicators .wfp--link {
    margin-top: 5px; }
  .offices-indicators__list-item {
    font-size: 14px; }
  .offices-indicators .wfp-tooltip {
    flex: 0 1 100%; }

.emergency-list {
  display: flex;
  flex-direction: column;
  color: white; }
  .emergency-list__items.full-size {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
  .emergency-list__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px; }
    .emergency-list__items .item {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .emergency-list__items .item .emergency-values {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal; }
        .emergency-list__items .item .emergency-values .value {
          font-weight: bold; }
        .emergency-list__items .item .emergency-values .value:after {
          font-family: FontAwesome;
          content: "\\f107";
          padding-left: 0.3em; }
        .emergency-list__items .item .emergency-values .without-arrow:after {
          display: none; }
    .emergency-list__items .item:last-child {
      padding-bottom: 0; }
  .emergency-list .emergency-level {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.79;
    letter-spacing: normal;
    text-transform: capitalize; }
  .emergency-list .emergency-level.M {
    background-color: #0a6eb4; }
  .emergency-list .emergency-level.L1 {
    background-color: #8c9ba5; }
  .emergency-list .emergency-level.L2 {
    background-color: #fd6926; }
  .emergency-list .emergency-level.L3 {
    background-color: #C5192C; }
  .emergency-list .emergency-level.SU img, .emergency-list .CA img, .emergency-list .AR img {
    width: 30px;
    height: 30px;
    object-fit: cover; }
  .emergency-list .emergency-level.SU img.small, .emergency-list .CA img.small, .emergency-list .AR img.small {
    width: 20px;
    height: 20px; }

.emergency-countries {
  padding: 5px 0 0 10px; }
  .emergency-countries .emergency-counties-list__item {
    font-weight: normal; }

.emergency-name {
  margin: 4px 0;
  font-size: 14px;
  font-weight: 600; }
  .emergency-name span {
    margin: 5px 0; }
  .emergency-name .emergency-counties-list__item {
    font-size: 14px; }

.emergency-counties-list__title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px; }

.emergency-counties-list__item {
  font-size: 13px;
  font-weight: bold;
  max-width: 190px;
  padding-bottom: 5px; }

.emergency-counties-list a {
  text-decoration: none; }

.info-source__item {
  font-size: 14px;
  line-height: 1.36;
  color: #031c2d;
  margin-bottom: 15px; }
  .info-source__item .title {
    font-weight: bold;
    font-size: 14px; }
  .info-source__item .info {
    display: flex;
    flex-flow: row wrap; }

.wfp--table .table-images th {
  padding: 0; }

.wfp--table .table-image {
  width: 65px;
  height: 65px;
  margin-bottom: 10px;
  box-sizing: content-box; }

.wfp--table .table-column-title {
  max-width: 140px;
  padding: 0; }
  .wfp--table .table-column-title__inner {
    margin: 15px 0 10px 21px; }
  .wfp--table .table-column-title p {
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle; }

.wfp--table .year {
  font-weight: 600; }

.wfp--table th {
  font-weight: 400;
  font-size: 14px; }

.wfp--table thead, .wfp--table th {
  background: initial;
  border-right: initial;
  border-bottom: 1px solid #dfe3e6;
  text-align: right; }

.wfp--table .table-images, .wfp--table .table-images th {
  border-bottom: initial; }

.wfp--table .active {
  background: #daeffb; }

.wfp--table tr:last-child th {
  border: none; }

.card {
  padding: 10px 14px;
  box-sizing: border-box;
  border: 1px solid #dfe3e6;
  min-width: 220px;
  border-radius: 5px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .card__wrap {
    flex-basis: 33%;
    box-sizing: border-box;
    display: none;
    padding: 10px; }
  .card__body {
    margin-top: 16px; }
  .card__title {
    font-size: 14px;
    font-weight: 600; }
    .card__title-container {
      display: flex;
      justify-content: space-between; }
    .card__title-image {
      width: 65px;
      height: 65px;
      margin-left: 15px; }
  .card .active {
    background: #daeffb; }
  .card__row {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfe3e6; }
    .card__row:last-child {
      border-bottom: 0; }
    .card__row-label {
      font-size: 14px;
      margin-right: 32px;
      font-weight: 600; }
    .card__row-value {
      font-size: 14px; }

@media (max-width: 794px) {
  .card__wrap {
    flex: 1; } }

.legend {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 0.7rem;
  line-height: 1.0rem; }
  .legend__label {
    font-style: normal;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .legend__label .bold {
      font-weight: bold;
      color: #000; }
    .legend__label .percents {
      width: 40px;
      text-align: right; }
    .legend__label .value {
      text-align: right; }
      .legend__label .value-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .legend__label .value-container .percents {
          margin-left: 10px; }

.chart-v2 {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .chart-v2 .doughnut-chart, .chart-v2 .progress-doughnut-chart {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center; }
    .chart-v2 .doughnut-chart .top-text, .chart-v2 .progress-doughnut-chart .top-text {
      fill: #031c2d; }
    .chart-v2 .doughnut-chart .center-text, .chart-v2 .progress-doughnut-chart .center-text {
      fill: #031c2d;
      font-weight: bold;
      font-size: 16px; }
    .chart-v2 .doughnut-chart .bottom-text, .chart-v2 .progress-doughnut-chart .bottom-text {
      font-size: 10px;
      font-weight: 600;
      width: 70px;
      line-height: 1.83;
      fill: #8c9ba5;
      text-transform: uppercase; }

@media screen and (max-width: 375px) {
  .chart-v2 {
    flex-direction: column; } }

.doughnut-legend {
  min-width: 140px;
  margin-top: 40px; }
  .doughnut-legend__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-left: 15px; }
    .doughnut-legend__item .color-sector {
      min-width: 15px;
      height: 15px;
      margin-top: 5px;
      margin-right: 5px;
      border-radius: 50%; }
    .doughnut-legend__item .title-sector {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      font-weight: bold; }
      .doughnut-legend__item .title-sector__title {
        line-height: 21px; }
      .doughnut-legend__item .title-sector__subtitle {
        font-weight: normal; }

.stacked-bar-chart-legend {
  display: flex;
  flex-direction: row;
  margin-top: 20px; }
  .stacked-bar-chart-legend .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px; }
    .stacked-bar-chart-legend .item__color-section {
      width: 15px;
      height: 15px;
      margin-right: 10px; }
    .stacked-bar-chart-legend .item__title-section {
      font-size: 14px;
      color: #5a6872; }

.radar-chart__legend {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-bottom: 10px; }
  .radar-chart__legend .radio-legend-item {
    font-size: 14px;
    color: #5a6872;
    padding-right: 5px;
    min-width: 230px; }

.bar-chart__legend {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  margin-bottom: 10px; }
  .bar-chart__legend .bar-legend-item {
    font-size: 14px;
    color: #5a6872;
    padding-right: 5px;
    min-width: 230px; }

.horizontal-bar-chart {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 0; }
  .horizontal-bar-chart__group {
    padding-bottom: 20px; }
    .horizontal-bar-chart__group .label {
      color: #031c2d;
      font-weight: 600;
      font-size: 14px;
      line-height: 23px; }
    .horizontal-bar-chart__group .indicator {
      display: flex;
      align-items: center; }
      .horizontal-bar-chart__group .indicator .bar {
        height: 14px;
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px; }
      .horizontal-bar-chart__group .indicator .value {
        font-size: 12px;
        color: #031c2d;
        margin-left: 5px;
        white-space: nowrap; }
    .horizontal-bar-chart__group .bars-wrap {
      position: relative; }

.legend-horizontal {
  display: flex;
  flex-wrap: wrap; }
  .legend-horizontal .mark-wrap {
    margin-right: 10px; }

.legend .mark-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px; }

.legend .mark {
  border-radius: 22px;
  margin-right: 7px;
  width: 16px;
  height: 16px; }

.legend .label {
  font-style: normal;
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #5a6872; }

.tooltip {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer; }

.area-chart-axis {
  display: flex;
  padding: 0 5px;
  flex-direction: row;
  justify-content: space-between; }
  .area-chart-axis__item {
    font-size: 10px;
    color: #5A6872;
    font-family: 'Open Sans', sans-serif; }

.dash-legend {
  display: flex;
  margin-top: 16px; }
  .dash-legend__item {
    margin-right: 8px;
    display: flex;
    align-items: center; }
  .dash-legend__sign {
    width: 18px;
    border-width: 1px;
    border-color: #000;
    margin-right: 8px; }
  .dash-legend__label {
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: #5a6872; }

.about__item .title {
  font-size: 16px;
  font-weight: bold; }

.about__item .sources-list .source {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
  font-size: 14px; }
  .about__item .sources-list .source__last-update {
    width: fit-content;
    margin-left: 5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #031c2d; }

.about__item .sources-list .source:last-child {
  padding-bottom: 10px; }

.about__help-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
  border-bottom: 1px solid #dedede;
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-size: 14px; }
  .about__help-text .last-update-title {
    text-align: right; }

.crisis-level {
  cursor: pointer;
  display: flex;
  width: 44px;
  height: 22px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.46;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out; }

.crisis-level.l1 {
  background-color: #8c9ba5; }

.crisis-level.l2 {
  background-color: #fd6926; }

.crisis-level.l3 {
  background-color: #C5192C; }

.crisis-level.m {
  background-color: #0a6eb4; }

.emergency-tags {
  display: flex;
  flex-direction: row; }
  .emergency-tags .crisis-level {
    width: 50px;
    height: 50px;
    border-radius: 0;
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px; }

.switcher__options {
  display: flex;
  flex-direction: column; }
  .switcher__options div {
    cursor: pointer;
    border-bottom: 1px solid #EFF2F5;
    color: #0b77c2;
    padding: 10px 0;
    text-transform: capitalize; }

.environment-indicator {
  position: fixed;
  width: 300px;
  height: 50px;
  background: #ffbf3b;
  top: 60px;
  right: -90px;
  text-align: center;
  font-size: 20px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  line-height: 50px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: .8;
  padding-left: 30px;
  z-index: 10; }

.environment-indicator.alpha {
  background: #ffbf3b; }

.environment-indicator.beta {
  background: #c5192d; }

.environment-indicator.qa {
  background: #8ad220; }

.environment-indicator.kitten {
  background: #fcc7c9; }

.ie-warring .wfp--modal-inner {
  justify-content: flex-start; }

.ie-warring__pre-title {
  font-size: 16px;
  margin-bottom: 5px; }

.ie-warring__title {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 30px; }

.ie-warring__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 30px; }
  .ie-warring__links img {
    width: 50px;
    height: 50px;
    margin-right: 15px; }

.glossary-menu .wfp--sub-navigation__title {
  display: flex; }

.custom-menu .menu-list__title-container {
  display: flex; }
  .custom-menu .menu-list__title-container .info-indicator {
    margin-left: 5px; }

.custom-menu .wfp--sub-navigation__content .wfp--sub-navigation__group .grouped-items {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4; }

.custom-menu .wfp--sub-navigation__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px; }

.custom-menu .wfp--sub-navigation__title {
  display: flex; }
  .custom-menu .wfp--sub-navigation__title .info-indicator {
    margin-left: 5px;
    margin-top: 3px; }

.custom-menu .wfp--sub-navigation__item {
  font-size: 14px; }
  .custom-menu .wfp--sub-navigation__item a {
    font-weight: normal;
    text-decoration: none; }

.custom-menu .region__subtitle {
  margin-bottom: 5px; }
  .custom-menu .region__subtitle a {
    font-size: 15px;
    text-decoration: none; }

.custom-menu .wfp--sub-navigation__filter,
.glossary-menu .wfp--sub-navigation__filter {
  flex-flow: wrap;
  width: initial; }

@media screen and (max-width: 875px) and (min-width: 768px) {
  .wfp--main-navigation__logo a {
    font-size: 14px !important; }
  .wfp--main-navigation__trigger a {
    font-size: 14px; } }

@media screen and (max-width: 600px) {
  .custom-menu .wfp--sub-navigation__filter .headingwrap__center.order,
  .glossary-menu .wfp--sub-navigation__filter .headingwrap__center.order {
    display: none; }
  .custom-menu .wfp--sub-navigation__content .wfp--sub-navigation__group .grouped-items {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }

.switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .switch__label {
    font-weight: 700; }
  .switch__control {
    margin-right: 10px; }
    .switch__control__container {
      display: flex;
      align-items: center; }

.hamburger-icon {
  cursor: pointer;
  padding: 2px;
  box-sizing: border-box; }
  .hamburger-icon:hover {
    fill: #0087cb; }

.hamburger-icon.active {
  outline: 2px solid #0a6eb4; }
  .hamburger-icon.active:hover {
    fill: #0a6eb4; }

.hamburger-container {
  margin: 0 25px 0 0; }

.hamburger-menu {
  padding: 15px;
  position: absolute;
  transform: translateY(2px);
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
  border-radius: 4px;
  font-size: .95rem;
  background: #fff; }
  .hamburger-menu a {
    color: #0a6eb4;
    text-decoration: none; }
  .hamburger-menu a:hover {
    color: #005e8d; }
  .hamburger-menu__item {
    margin-bottom: 20px; }
    .hamburger-menu__item-label {
      font-weight: 600;
      font-size: 16px; }
  .hamburger-menu__item:last-child {
    margin-bottom: 0; }
  .hamburger-menu__panels {
    display: flex;
    flex-direction: column;
    margin-left: 30px; }
  .hamburger-menu__panel-label {
    margin-top: 10px;
    font-size: 14px; }

.pie-chart-tooltip {
  position: absolute;
  background: #031c2d;
  padding: 7px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between; }
  .pie-chart-tooltip span {
    white-space: nowrap; }
  .pie-chart-tooltip__label {
    color: white;
    font-weight: 600;
    font-size: 14px;
    margin-right: 20px; }
  .pie-chart-tooltip__value {
    color: white;
    font-size: 14px; }
  .pie-chart-tooltip__caret {
    content: '';
    position: absolute;
    background: #031c2d;
    width: 7px;
    bottom: -3.5px;
    margin: 0 auto;
    height: 7px;
    transform: rotate(45deg); }

.label-tooltip {
  position: absolute;
  top: -25px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: 300;
  color: #031c2d;
  background: #eeeeee;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap; }

.tabs {
  position: sticky;
  top: 0;
  z-index: 2; }
  .tabs__container {
    display: flex;
    align-items: flex-end; }
  .tabs .wfp--tabs__nav-item {
    font-size: 1rem; }
    .tabs .wfp--tabs__nav-item a:hover {
      color: #0087cb; }
    .tabs .wfp--tabs__nav-item--selected a:hover {
      color: #0a6eb4; }
  .tabs__emergency-title {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    min-height: 70px;
    font-weight: 600; }
    .tabs__emergency-title .title-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap; }
      .tabs__emergency-title .title-wrap .title {
        margin-right: 15px;
        font-size: 100%;
        display: flex; }
      .tabs__emergency-title .title-wrap .links {
        display: flex;
        flex-direction: row;
        align-self: center;
        font-size: 14px;
        margin-bottom: -10px; }
        .tabs__emergency-title .title-wrap .links .acr-link {
          margin-left: 20px; }
        .tabs__emergency-title .title-wrap .links .acr-link, .tabs__emergency-title .title-wrap .links .csp-link {
          text-decoration: none; }
          .tabs__emergency-title .title-wrap .links .acr-link:hover, .tabs__emergency-title .title-wrap .links .csp-link:hover {
            text-decoration: underline; }
  .tabs .wfp--secondary-navigation__list {
    padding-top: 0; }

.breadcrumbs {
  padding-top: 1.5rem; }

.programme-tab-content {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 20px; }
  .programme-tab-content__title {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 600; }

.tab-content-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  color: #031c2d;
  min-height: 80px; }
  .tab-content-header__label {
    margin-bottom: 20px; }
    .tab-content-header__label .title {
      font-size: 24px;
      font-weight: 600; }
    .tab-content-header__label .subtitle {
      font-size: 14px;
      color: #000000; }
  .tab-content-header__filters {
    display: flex;
    flex-flow: row wrap; }
    .tab-content-header__filters .wfp--inline-loading {
      width: auto; }
    .tab-content-header__filters .filter-item {
      width: 115px; }
      .tab-content-header__filters .filter-item__label {
        line-height: 1.29;
        color: #031c2d;
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 7px; }
    .tab-content-header__filters .filter-item:not(:last-child) {
      margin-right: 10px;
      margin-bottom: 10px; }

.line-rectangle-indicators {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  min-height: 200px; }
  .line-rectangle-indicators__indicator {
    background-color: white;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 2px #dfe3e6;
    border-radius: 4px; }
    .line-rectangle-indicators__indicator .currency {
      font-size: 16px;
      line-height: 1.31; }
    .line-rectangle-indicators__indicator .value {
      font-size: 35px;
      font-weight: 300;
      line-height: 0.6;
      margin-top: 10px;
      margin-bottom: 20px; }
    .line-rectangle-indicators__indicator .label {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.31; }

.strategic-outcome-activity {
  margin: 0 40px 40px 40px;
  border: 2px solid #dfe3e6;
  border-radius: 4px; }
  .strategic-outcome-activity__title {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.94;
    background-color: #5a6872;
    padding: 15px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .strategic-outcome-activity__content .percent-indicators {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); }
    .strategic-outcome-activity__content .percent-indicators .big-percent-indicator {
      padding: 0 25px;
      margin: 25px 0;
      border-right: 1px solid #dfe3e6; }
    .strategic-outcome-activity__content .percent-indicators .big-percent-indicator:last-child {
      border-right: none; }
    .strategic-outcome-activity__content .percent-indicators .big-percent-indicator__sub-labels {
      display: flex;
      flex-direction: column; }
      .strategic-outcome-activity__content .percent-indicators .big-percent-indicator__sub-labels .left, .strategic-outcome-activity__content .percent-indicators .big-percent-indicator__sub-labels .right {
        display: flex;
        flex-direction: row; }
        .strategic-outcome-activity__content .percent-indicators .big-percent-indicator__sub-labels .left .label, .strategic-outcome-activity__content .percent-indicators .big-percent-indicator__sub-labels .right .label {
          min-width: 80px; }
  .strategic-outcome-activity__content .outputs-table {
    padding: 25px 25px 0 25px; }
    .strategic-outcome-activity__content .outputs-table .rt-thead.-header {
      border-top: 1px solid #dfe3e6;
      border-bottom: 1px solid #dfe3e6; }
      .strategic-outcome-activity__content .outputs-table .rt-thead.-header .rt-th {
        min-height: 96px;
        padding: 15px 20px; }
    .strategic-outcome-activity__content .outputs-table .rt-th, .strategic-outcome-activity__content .outputs-table .rt-td {
      border-right: 1px solid #dfe3e6; }
    .strategic-outcome-activity__content .outputs-table .resources {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .strategic-outcome-activity__content .outputs-table .resources .one-line-indicator {
        font-size: 16px;
        line-height: 1.38;
        width: 100%;
        padding-bottom: 20px; }
        .strategic-outcome-activity__content .outputs-table .resources .one-line-indicator .label {
          font-weight: normal;
          text-transform: none;
          width: 281px;
          padding-right: 20px; }
        .strategic-outcome-activity__content .outputs-table .resources .one-line-indicator .two-rows-cell {
          display: flex;
          flex-direction: row; }
          .strategic-outcome-activity__content .outputs-table .resources .one-line-indicator .two-rows-cell__value {
            padding-right: 10px; }
      .strategic-outcome-activity__content .outputs-table .resources .one-line-indicator:last-child {
        padding-bottom: 0; }
    .strategic-outcome-activity__content .outputs-table .rt-tr-group:last-child {
      border-bottom: none; }
  .strategic-outcome-activity__info {
    min-width: 150px; }
    .strategic-outcome-activity__info .title {
      font-weight: 600;
      font-size: 16px; }
    .strategic-outcome-activity__info .one-line-indicator {
      font-size: 14px;
      margin-top: 5px; }
      .strategic-outcome-activity__info .one-line-indicator .label {
        font-weight: normal; }

.management-switchers {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 14px; }
  .management-switchers__elements {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 5px; }
    .management-switchers__elements .item-wrap {
      flex: inherit;
      margin-right: 40px; }
  .management-switchers__title {
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
    padding-bottom: 5px; }
  .management-switchers .wfp--form-item.wfp--checkbox-wrapper {
    margin-bottom: 0; }
  .management-switchers .wfp--form-item.wfp--checkbox-wrapper:first-of-type {
    margin-top: 0; }

.tippy-tooltip.dark-v2-theme {
  background-color: #344D5C;
  color: white; }

.hamburger-theme {
  background: #fff; }

.tippy-tooltip.dark-v2-theme[data-animatefill] {
  background-color: transparent; }

.tippy-tooltip.dark-v2-theme .tippy-backdrop {
  background-color: #344D5C; }

.mobile__header {
  box-sizing: border-box;
  background: #fff;
  background: linear-gradient(135deg, white 0%, #f6f6f6 100%);
  border-bottom: 2px solid #E1E7EB; }
  .mobile__header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .mobile__header-main {
    flex-basis: 60%; }
    .mobile__header-main__title {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 45px; }
    .mobile__header-main__desc {
      margin-bottom: 50px; }
    .mobile__header-main__links {
      display: flex;
      align-items: center; }
    .mobile__header-main__link {
      cursor: pointer;
      text-decoration: underline;
      color: #0b77c2;
      margin-right: 30px;
      font-size: 18px; }
    .mobile__header-main__link-image {
      cursor: pointer; }
  .mobile__header-image {
    margin-top: 40px;
    height: 600px;
    object-fit: cover; }

.mobile__main {
  padding: 80px 10px;
  box-sizing: border-box; }
  .mobile__main-section {
    margin-bottom: 100px; }
  .mobile__main-section:last-child {
    margin-bottom: 0; }
  .mobile__main-label {
    font-family: 'Open Sans', sans-serif;
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 50px; }
  .mobile__main-steps {
    margin-bottom: 60px; }

.mobile__step {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 60px; }
  .mobile__step-label {
    font-size: 20px;
    margin-bottom: 20px; }
  .mobile__step-row {
    display: flex;
    justify-content: space-between; }
  .mobile__step__image-container {
    display: flex;
    justify-content: center;
    align-items: center; }
    .mobile__step__image-container .image {
      width: 350px;
      max-height: 350px;
      background: rgba(196, 196, 196, 0.1); }
      .mobile__step__image-container .image-fill {
        padding: 20px 40px;
        box-sizing: border-box;
        object-fit: contain;
        border: 1px solid #EDF1F3;
        background: none; }
  .mobile__step-list {
    flex-grow: 1;
    margin-left: 50px;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: initial; }
    .mobile__step-list .item {
      margin-bottom: 15px;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px; }
      .mobile__step-list .item .link {
        color: #031c2d; }

.mobile__step:last-child {
  margin-bottom: 0; }

.mobile__help {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;
  background: #C2DBEC;
  border-radius: 5px; }
  .mobile__help__title {
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px; }
  .mobile__help__desc {
    margin-bottom: 40px;
    max-width: 600px;
    line-height: 26px;
    text-align: center; }
  .mobile__help__link {
    background: #0b77c2;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    text-decoration: none; }

@media (max-width: 1000px) {
  .mobile__step-row {
    flex-wrap: wrap;
    justify-content: center; }
  .mobile__header-main__title {
    font-size: 35px; }
  .mobile__header-main__links {
    flex-direction: column;
    align-items: flex-start; }
  .mobile__header-main__link {
    margin-left: 0;
    margin-bottom: 20px; } }

@media (max-width: 750px) {
  .mobile__header {
    padding: 20px; }
    .mobile__header-image {
      display: none; }
    .mobile__header-main {
      flex-basis: initial; }
  .mobile__step-list {
    margin-left: 0;
    margin-top: 20px; } }

@media (max-width: 370px) {
  .mobile__step__image-container .image {
    width: 100%; } }

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px;
  height: 90vh; }
  .error-page__icon {
    transform: rotate(10deg); }
  .error-page__title {
    font-size: 3.375rem;
    font-weight: 300;
    margin: 44px 0; }

.content {
  padding: 20px 0;
  font-family: 'Open Sans', sans-serif;
  color: #031c2d;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

.content:empty {
  padding: 0 !important; }

.bold {
  font-weight: bold; }

.white-bg {
  background-color: white; }

.wfp--tabs a:focus {
  box-shadow: none;
  -webkit-box-shadow: none; }

.wfp--module {
  padding: 0; }
  .wfp--module__inner .wfp--module__title {
    font-size: 16px; }

.wfp-secondarynav__director {
  max-width: 200px; }

.wfp--main-navigation__logo a {
  font-size: 20px;
  font-weight: 700; }

.wfp--modal-content p {
  margin-block-start: 1em;
  margin-block-end: 1em; }

.wfp--modal-content ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px; }

.wfp--modal-content ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px; }

.wfp--modal-content li {
  line-height: 1.4; }

.wfp--tabs__nav-item a {
  padding: 0.82rem 0.5rem;
  font-size: 20px;
  color: #031c2d;
  font-weight: 400; }

.wfp--tabs__nav-item--selected a {
  color: #0a6eb4; }

.wfp--tabs__nav-item--disabled a {
  color: white;
  cursor: default; }

.wfp--tabs__nav__bar {
  bottom: 1.8px; }

.wfp--secondary-navigation__list {
  min-width: 100%; }

.initializing-icon {
  justify-content: center;
  display: flex; }

.google-play-link img {
  height: 50px; }

.tooltip-info ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px; }

.wfp--user__title {
  display: none; }

.wfp--tabs__nav {
  align-items: flex-end;
  overflow: hidden; }

.wfp--banner-navigation .wfp--wrapper {
  max-width: 1200px; }

.react-autosuggest__suggestions-container li > .suggestion__content-empty:hover {
  background: #fff; }

.wfp--main-navigation__user .menu__title {
  max-width: 220px;
  word-break: break-word;
  font-weight: 700; }

.wfp--main-navigation__user .wfp--main-navigation__sub {
  min-width: 350px; }
  .wfp--main-navigation__user .wfp--main-navigation__sub .wfp-btn:hover {
    border-width: 2px;
    border-style: solid;
    border-color: transparent; }
  .wfp--main-navigation__user .wfp--main-navigation__sub .wfp--sub-navigation__group__title {
    font-size: 16px;
    font-weight: 700; }
  .wfp--main-navigation__user .wfp--main-navigation__sub .wfp--link {
    font-size: 16px;
    font-weight: 400; }
  .wfp--main-navigation__user .wfp--main-navigation__sub .wfp--sub-navigation__link {
    flex-grow: 0; }

.arrow:after {
  font-family: FontAwesome;
  content: "\\f107";
  padding-left: 0.3em; }

.pdf-source-info {
  margin-top: 20px; }

@media screen and (min-width: 992px) {
  .wfp--main-navigation__user .wfp--main-navigation__sub--open {
    left: inherit;
    right: 0;
    width: 25%;
    min-width: 350px; } }

@media screen and (max-width: 500px) {
  .wfp--tabs__nav-item a {
    font-size: 14px; } }

button {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
  font-family: 'Open Sans', sans-serif; }

button:hover {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent; }

button:active {
  outline: none;
  border: none; }

button:focus {
  outline: 0; }

.navy-bg {
  background-color: #031c2d; }

`;

pdfStyles += `
.local-economies-panel .bottom-text {
  font-size: 11px !important; }
.wfp--module__footer { display: none !important; } 
.wfp--module__title { font-size: 25px !important; margin-bottom: 20px !important; }
.wfp--module__title { 
  font-size: 25px !important; margin-bottom: 20px !important;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #031c2d; 
}
.content-wrap { padding: 0 40px; }
.population-item.global .population-item-row__value { margin-right: 0; }
.population-item .breakdown:after { content: none; }
.emergency-list__items .item .emergency-values .value:after { content: none; }
.beneficiaries-dashboard .title__container .breakdown:after { content: none; }
.wfp-secondarynav__director:after { content: none; }
 .wfp--wrapper p, .wfp--wrapper h1, .wfp--wrapper h2, .wfp--wrapper h3, .wfp--wrapper h4, .wfp--wrapper h5, .wfp--wrapper h6 { margin: 0 }
 * { box-sizing: border-box; }
 .bar-indicator__prev, .compare-bar-indicator__prev { border-width: 0; border-left-width: 2px; } 
 .focus-areas-charts { justify-content: flex-start; }
`;

export { pdfStyles };
